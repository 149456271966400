.qr-code-scanner {
    text-align: center;
    padding: 20px;
  }
  
  .qr-code-scanner h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }
  
  .qr-code-scanner h3 {
    font-size: 1.2em;
    margin-bottom: 1em;
  }
  
  .qr-code-scanner div {
    display: inline-block;
  }
  